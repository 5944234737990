import React, {useEffect, useReducer, useState} from 'react';
import {Button, TextField, Typography} from "@material-ui/core";
import {cyberBanners} from "../../../assets/images";
import {INITIAL_STATE, reducer} from "./reducer";
import {API_URL, APP_ECOMMERCE} from "../../../config";
import {isEmail} from "../../../utils";
import {bannerStyles} from "./styles";

const background = APP_ECOMMERCE === 'enova' ? '#894093' : '#223092';

export const CyberWeek = () => {

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [width, setWidth] = useState(window.innerWidth);

    const handleSubmit = async () => {

        if (isEmail(state.email)) {
            const header = new Headers();
            header.append("Content-Type", "application/json")
            let response = await fetch(`${API_URL}/subscription/new/2`, {
                headers: header,
                method: 'POST',
                body: JSON.stringify(state)
            });
            response = await response.json();
            if (response) {
                alert('Correo guardado');
                dispatch({type: 'name', payload: ''})
                dispatch({type: 'email', payload: ''})
            } else {
                alert('El correo ya existe');
                dispatch({type: 'name', payload: ''})
                dispatch({type: 'email', payload: ''})
            }

        } else {
            alert('El mail no es valido');

        }
    }

    const stylesBanner = bannerStyles(background,width);

    useEffect(() => {
        window.addEventListener('resize', ()=> {
            setWidth(window.innerWidth);
        })

        return () => window.removeEventListener('resize', ()=> {
            setWidth(window.innerWidth);
        });

    }, []);

    return (
        <div style={stylesBanner.containerRow}>
            <div style={stylesBanner.top}>
                <img src={cyberBanners[2]} style={{height: '80px', marginRight: '20px'}} alt='banner'/>
                <Typography style={{...stylesBanner.text, width: '200px', fontSize: '20px'}}>06, 07 y 08 DE
                    NOVIEMBRE</Typography>
            </div>
            <div style={stylesBanner.containerColumn}>
                <div style={{...stylesBanner.boxRight, marginRight: '10px'}}>
                    <img src={APP_ECOMMERCE === 'enova' ? cyberBanners[0] : cyberBanners[1]} style={{height: '400px'}} alt='banner'/>
                    <Typography style={{...stylesBanner.text, fontSize:'9px', marginTop: '10px', marginBottom: width > 970 ? '0px': '20px', width: width > 970 ? '70%': '44%', fontWeight: 400}}>
                        Smart Tv - Celulares - Notebooks - Tablets
                    </Typography>
                </div>
                <div style={{...stylesBanner.boxLeft, marginLeft: '10px'}}>
                    <div style={stylesBanner.container}>
                        <Typography style={stylesBanner.text}>Suscribite y conocé
                            las ofertas antes que nadie</Typography>
                    </div>
                    <div style={stylesBanner.formContainer}>
                        <form>
                            <TextField value={state?.name} variant='standard' onChange={ (e,) => dispatch({type: 'name', payload: e.target.value})} label="Nombre *" InputLabelProps={{style: {fontSize: 15}, shrink: true}}
                                       style={stylesBanner.textField}/>
                            <TextField value={state?.email} onChange={ (e,) => dispatch({type: 'email', payload: e.target.value})} label="Dejá tu correo aquí *" InputLabelProps={{style: {fontSize: 15}, shrink: true}}
                                       style={stylesBanner.textField}/>
                            <Button onClick={() => handleSubmit()} type='button' variant="contained" color="primary" style={stylesBanner.textField}>
                                suscribirme
                            </Button>
                        </form>
                    </div>
                    <Typography  style={{...stylesBanner.text, fontSize:'8px', marginTop: '10px', width:'60%', fontWeight: 400}}>
                        Mediante el presente dejo constancia que he sido debidamente informado respecto de la finalidad para la cual han sido recabados mis datos personales y quiénes pueden ser sus destinatarios,
                        como así también del derecho que me asiste a tener acceso a los mismos, como así a peticionar su rectificación y/o supresión, todo ello conforme a lo normado en la Ley Nacional N° 25326
                        (Protección de Datos Personales) y su Decreto Reglamentario N° 1558/01.
                    </Typography>
                </div>
            </div>
        </div>
    )
};
